import React from "react"
import Layout from "../../components/shared/layoutParallax"
import SEO from "../../components/SEO/SEOPage"
import styled from "@emotion/styled"
import { breakpoints } from "../../utils/style"
import Auth from "../../components/Auth/Auth"
import GameIframe from "../../components/Content/Games/Iframe"
// import WithdrawCommentsStaticLoadmore from '../../components/Content/Withdraw/Comments/CommentsStaticLoadmore';
import Breadcrumbs from "../../components/shared/Breadcrumbs"

const WrapperDesktop = styled("div")`
  max-width: 1254px;
  margin: 0 auto;
  padding: 5px 15px;
  @media (max-width: ${breakpoints.md}) {
    padding: 0 15px;
  }
`

const GameWrapper = styled("div")`
  .auth-wrapper {
    margin: 0;
    width: auto;
    float: right;
  }
  @media (max-width: ${breakpoints.sm}) {
    .auth-wrapper {
      float: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      .account-accordion {
        margin: 0;
      }
    }
  }
`

// const WrapperPadded = styled('div')`
//   ${wrapper};
//   margin: 20px auto 40px;
// `;

const Game = ({ pageContext }) => {
  const breadcrumbs = pageContext.breadcrumbs
  const page_title = pageContext.page_title
  const game_name = pageContext.name
  const game_code = pageContext.code
  return (
    <Layout>
      <SEO
        title={game_name}
        description={game_name}
        pathname={"/game/" + game_code + "/"}
        breadcrumbs={breadcrumbs}
      />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
        <GameWrapper>
          <Auth />
          <h1>เกม {page_title}</h1>
          <GameIframe game_code={game_code} />
        </GameWrapper>
      </WrapperDesktop>
      {/*<WrapperPadded>*/}
      {/*<WithdrawCommentsStaticLoadmore/>*/}
      {/*</WrapperPadded>*/}
    </Layout>
  )
}

export default Game
