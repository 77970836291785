import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, color } from '../../../utils/style';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const GameAds = styled('div')`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
`;

const GameAd = styled('div')`
  width: 23%;
  position: relative;
  margin-bottom: 30px;
  .show-more, .coming-soon {
    width: 120px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left:50%;
    margin: -25px 0 0 -60px;
    display: none;
  }
  .coming-soon {
    background: transparent;
    color: ${color.white};
    padding: 12px;
    text-align: center;
    pointer-events: none;
    width: 150px;
    margin: -25px 0 0 -75px;
  }
  .ribbon {
    position: absolute;
    top: 12%;
    left: -15px;
    z-index: 1;
    padding: 5px 10px;
    background: ${color.red};
    font-style: italic;
    font-weight: bold;
    &::after {
      content: "";
      width: 0; 
      height: 0;
      border-top: 28px solid ${color.red};
      border-right: 15px solid transparent;
      position: absolute;
      right: -15px;
      top: 0;
    }
    &::before {
      content: "";
      width: 0; 
      height: 0;
      border-bottom: 28px solid ${color.red};
      border-left: 15px solid transparent;
      position: absolute;
      left: -15px;
      top: 0;
    }
  }
  &:hover, &:active {
    .ribbon {
      opacity: 0.8;
    }
    .coming-soon, .show-more {
      display: inline-block;
    }
  }
  a, .a {
    display: block;
    &:hover, &:active {
      img {
        filter: brightness(20%);
      }
    }
  }

  .gatsby-image-wrapper {
    border-radius: 24px;
  }
  @media (max-width: ${breakpoints.md}) {
    .coming-soon {
      font-size: 12px;
      margin: -10px 0 0 -57px;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    width: 47%;
    margin: 1.2%;
    .coming-soon {
      font-size: 0.8rem;
    }
  }
  
`;

const PlayForFreeGame = ({ exclCode }) => {
  const gameClick = slide_description => {
    if (slide_description) {
      return false;
    }
  };
  return(
    <StaticQuery
      render={data => (
        <GameAds>
          {data.allWordpressPage.edges[0].node.acf.play_for_free.map((item) => {
            const localFile = item.localFile || {};
            if (!item.acf.slide_description && item.acf.slide_url && item.title !== exclCode) {
              return (
                <GameAd key={item.path}>
                  {item.acf.slide_title &&
                  <span className="ribbon">{item.acf.slide_title}</span>
                  }
                  {item.acf.slide_url ?
                    <a href={item.acf.slide_url} title={item.alt_text}
                       onClick={(e)=>gameClick(e, item.title, item.alt_text, item.acf.slide_description)}>
                      {!item.acf.slide_description &&
                      <span className="button gold-button show-more">เล่นเลย</span>
                      }
                      {localFile.childImageSharp && localFile.childImageSharp && item.alt_text &&
                      <Img fluid={localFile.childImageSharp.fluid} alt={item.alt_text} fadeIn={false} loading='eager' />
                      }
                    </a> :
                    <div className="a">
                      {localFile.childImageSharp && localFile.childImageSharp && item.alt_text &&
                      <Img fluid={localFile.childImageSharp.fluid} alt={item.alt_text} fadeIn={false} loading='eager' />
                      }
                    </div>
                  }
                </GameAd>
              )
            } else {
              return null;
            }
          })}
        </GameAds>
      )}
      query=
        {graphql`
        query {
          allWordpressPage(
            filter: {
              slug: {eq: "home-page"}
            }
          ) {
            edges {
              node {
                acf {
                  play_for_free_title
                  play_for_free_video_cover {
                    alt_text
                    title
                    path
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 620) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                  play_for_free {
                    alt_text
                    title
                    path
                    acf {
                      slide_url
                      slide_title
                      slide_description
                    }
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 400) {
                          ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
    />
  )};

export default PlayForFreeGame;
