import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { color, breakpoints } from '../../../utils/style';
import { getAuthToken, Logout } from '../../../components/Auth/Auth';
import { domain } from '../../../components/Auth/Config.js';
import IcomoonIcon from '../../shared/IcomoonIcon';
import StoreContext from '../../shared/Context/StoreContext.js';
import PlayForFreeGame from './PlayForFreeGame';
import DefaultGameBanner from './Images/DefaultGameBanner'
import { getAuthHeader } from '../../Auth/Utils';
import { gameIframeUrl } from './Config';

const Wrapper = styled('div')`
  .green-button {
    margin: 10px 0;
    svg {
      margin-right: 7px;
    }
  }
  .iframe-container-game {
    position: relative;
    overflow: hidden;
    padding-top: 74.025%;
    clear: both;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
  .gatsby-image-wrapper {
    border-radius: 10px;
  }
  .not-logged-in {
    position: relative;
    .button {
      position: absolute;
      z-index: 1;
      top: 3%;
      left: 50%;
      margin-left: -60px;
      box-shadow: 2px 2px 7px 10px rgba(0, 0, 0, 0.5);
    }
  }

  @media (min-width: ${breakpoints.mdMin}) and (max-width: ${breakpoints.xl}) {
    padding: 0 10%;
  }

  .mobile-portrait {
    display: none;
    margin: 50px 0 60px;
    text-align: center;
    position: relative;
    height: 100px;
    .gatsby-image-wrapper {
      opacity: 0.6;
    }
    .rotate-icon-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding-top: 5px;
    }
    p {
      margin: 15px;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    .mobile-landscape {
      display: none;
    }
    .mobile-portrait {
      display: block;
    }
    &.landscape-mode {
      .mobile-portrait {
        display: none;
      }
      .mobile-landscape {
        display: block;
        position: fixed;
        width: 100vw;
        z-index: 100;
        top: 0;
        left: 0;
        .iframe-container-game {
          width: auto;
          position: static;
          overflow: auto;
          padding-top: 0;
          iframe {
            position: static;
            width: 100vw;
            height: 100vh;
          }
        }
      }
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .mobile-portrait {
      margin-top: 0;
    }
  }
`;

const Iframe = ({ iframeUrl }) => {
  return <iframe title="game-iframe-home" scrolling={'no'} src={iframeUrl} />;
};

const NoLoggedIn = ({ children }) => {
  return <div className="not-logged-in">{children}</div>;
};
const Loader = () => {
  return <div>Loading...</div>;
};

const getIframeUrl = async (game_code, setGameurl) => {
  if (!getAuthToken) {
    return false;
  }
  let body = JSON.stringify({ code: game_code });
  const response = await fetch(gameIframeUrl, {
    method: 'POST',
    body: body,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getAuthHeader(),
    },
  });
  if (response.ok) {
    let json = await response.json();
    if (json.error && json.message) {
      console.log(json.message);
      console.log(json.error_type);
      if (json.error_type === 'session_expired') {
        console.log('Call Logout from Iframe');
        Logout(false, setUsernameCallback);
      }
    } else {
      setGameurl(json.url);
    }
  } else {
    let json = await response.json();
    if (json.code === 'jwt_auth_invalid_token') {
      Logout(false, setUsernameCallback);
    }
  }
};
let setUsernameCallback = false;

const GameIframe = ({ game_code }) => {
  const value = useContext(StoreContext);
  const username = value.username;
  const setUsernameCallback = value.setUsernameCallback;

  const url = domain + '/wp-json/freepsin/v1/game';
  const [gameurl, setGameurl] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);

    let pureMQL = window.matchMedia('(orientation: landscape)');
    const gameWrapper = document.getElementById('game-page-wrap');
    pureMQL.addListener(function(m) {
      if (m.matches) {
        gameWrapper.classList.add('landscape-mode');
      } else {
        gameWrapper.classList.remove('landscape-mode');
      }
    });
    getIframeUrl(game_code, setGameurl);
  }, [username, game_code, url, setUsernameCallback]);

  return (
    <Wrapper id="game-page-wrap">
      {username && (
        <div>
          <div className="logged-in-user" id="logged-in-user">
            {gameurl ? (
              <div className="mobile-landscape">
                <div className="iframe-container-game">
                  <Iframe iframeUrl={gameurl} />
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </div>
          <div className="mobile-portrait">
            <div className="rotate-icon-container">
              <IcomoonIcon
                icon="mobile-rotate"
                color={color.orange}
                size={80}
              />
              <p>โปรดหมุนอุปกรณ์เคลื่อนที่เพื่อเล่นในแนวนอน</p>
            </div>
          </div>
        </div>
      )}
      {!username && (
        <NoLoggedIn>
          <DefaultGameBanner/>
        </NoLoggedIn>
      )}
      <PlayForFreeGame exclCode={game_code} />
    </Wrapper>
  );
};

export default GameIframe;
